<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateTeamModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="teams.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="teams.count"
        :loading="loading"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.team.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.team.entity') })"
        flat
      >
        <template v-slot:[`item.duties`]="{ item }">
          <span class="wrap-text">{{ item.duties }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="editTeam(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteTeam(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      isModalVisible: false,
      loading: false,
      title: {
        text: this.$tc('models.team.entity', 2),
        icon: 'mdi-account-group'
      },
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.team.attributes.name'),
            rules: [{name: 'required'}],
            classes: 'col-12 py-0',
            serverErrors: []
          },
          duties: {
            type: 'textarea',
            key: 'duties',
            value: '',
            label: this.$t('models.team.attributes.duties'),
            classes: 'col-12 py-0',
            serverErrors: []
          }
        }
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'Teams'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.teams'),
          to: {name: 'Teams'},
          exact: true
        }
      ],
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.team.entity', 1)}),
        icon: 'mdi-plus'
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      headers: [{
        text: '',
        value: 'id',
        align: ' d-none'
      },
      {
        text: this.$t('models.team.attributes.name'),
        value: 'name'
      },
      {
        text: this.$t('models.team.attributes.duties'),
        value: 'duties'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '120px'
      }]
    };
  },
  computed: mapGetters(['teams']),
  methods: {
    ...mapActions([
      'getTeams',
      'destroyTeam',
      'createTeam',
      'updateTeam'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getTeams(options).then(() => this.loading = false);
    },
    submitModal(data) {
      let form;
      let request;
      switch (this.modal.behaviour) {
      case 'createTeam':
        request = this.createTeam(data);
        break;
      case 'updateTeam':
        form = {
          teamId: this.modal.id,
          form: data
        };
        request = this.updateTeam(form);
        break;
      }
      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editTeam(data) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.resetForm = false;
      this.modal.behaviour = 'updateTeam';
      this.modal.title = this.$t('views.teams.updateTeam');
      this.modal.fields.name.value = data.name;
      this.modal.fields.duties.value = data.duties;
      this.modal.id = data.id;
      this.modal.show = true;
    },
    openCreateTeamModal() {
      this.modal.resetForm = true;
      this.modal.behaviour = 'createTeam';
      this.modal.title = this.$t('views.teams.newTeam');
      this.modal.fields.name.value = '';
      this.modal.fields.duties.value = '';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteTeam(team) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: team.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyTeam(team.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrap-text {
  display: table;
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
}
</style>
